export const cartStore = {
    namespaced: true,
    state: {
        cart: []
    },
    getters: {
        getCart: (state) => {
            console.log(state)
            return state.cart;
        },
    },
    mutations: {
        setCart: (state, cart) => {
            state.cart = cart;
        },
        addToCart: (state, element) => {
            state.cart.push(element);
        }
    }
};
