<template>
    <div id="app">
        <div class="el-menu">
            <el-menu fixed="top" router :default-active="'/'" background-color="#123c69"
                     text-color="#ffffff"

                     active-text-color="#edc7b7" mode="horizontal">
                <!--                -->
                <!--                Все залогиненные пользователи видят-->
                <!--                -->
                <el-menu-item :index="'/'">
                     <span class="nauka-menu-item">
                        <i class="fas fa-home"></i>
                       {{ $t("Главная") }}</span>
                </el-menu-item>
                <!--        <el-menu-item v-if="currentUser" :index="'/how-to-use'"><span-->
                <!--            class="nauka-menu-item">Пользование системой</span>-->
                <!--        </el-menu-item>-->
                <!--                -->
                <!--                Все админы видят-->
                <!--                -->
                <el-menu-item v-if="currentUser && ((adminStatus) || (konstruktorStatus))" :index="'/game'"><span
                        class="nauka-menu-item">{{ $t("Онтология") }}</span>
                </el-menu-item>
                <el-menu-item v-if="currentUser && ((adminStatus) || (konstruktorStatus) || (analitikStatus))"
                              :index="'/cases'"><span
                        class="nauka-menu-item">{{ $t("Кейсы") }}</span>
                </el-menu-item>
                <!--                -->
                <!--                Все пользователи и админы начавшие игру Икс видят-->
                <!--                -->
                <!--                <el-menu-item v-if="(currentUser) && (adminStatus) && (allSessions.length)" :index="'/session'"><span-->
                <!--                        class="nauka-menu-item">Игра</span>-->
                <!--</el-menu-item>-->
                <el-submenu v-if="(currentUser) && (allSessions.length)" :index="'/session'">
                    <template v-slot:title>{{ $t("Расследование") }} {{ CurrentSession.caseName }}</template>

                    <el-menu-item v-for="session in allSessions" :key="session" :index="'/session'"
                                  :ref="session._id"
                                  @click="setCurrentSession(session)">
                        <span class="nauka-menu-item">{{ session.caseName }}</span>
                    </el-menu-item>

                </el-submenu>


                <el-menu-item v-if="(adminStatus || kuratorStatus)" :index="'/tribune'"><span
                        class="nauka-menu-item">{{ $t("Результаты") }}</span>
                </el-menu-item>
                <!--
                 -->
                <!--                Статус пользователя -->
                <!--                -->
                <!--                <el-menu-item v-if="showAdminBoard" :index="'/admin'"><span class="nauka-menu-item">Admin Board</span>-->
                <!--                </el-menu-item>-->

                <!--                <el-menu-item v-if="showModeratorBoard" :index="'/mod'"><span-->
                <!--                        class="nauka-menu-item">Moderator Board</span></el-menu-item>-->
                <!--                -->
                <!--                Вход/Выход в систему -->
                <!--                -->
                <el-menu-item v-if="!currentUser" :index="'/register'">
                        <span class="nauka-menu-item">
                            <i class="fas fa-user-plus"></i>
                            {{ $t("Зарегистрироваться") }}
                        </span>
                </el-menu-item>
                <el-menu-item v-if="!currentUser" :index="'/login'">
                        <span class="nauka-menu-item">
                            <i class="fas fa-sign-in-alt"></i>
                            {{ $t("Войти") }}
                        </span>
                </el-menu-item>
                <!--                </div>-->
                <!--                <div v-if="currentUser" class="navbar-nav ml-auto">-->
                <el-menu-item v-if="currentUser" :index="'/profile'">
                        <span class="nauka-menu-item">
                             <i class="fas fa-user"></i>
                            {{ currentUser.username }}
                        </span>
                </el-menu-item>
                <el-menu-item v-if="currentUser">
                    <a href @click.prevent="logOut">
                        <span class="nauka-menu-item">
                            <i class="fas fa-sign-out-alt"></i>
                            {{ $t("Выход") }}
                        </span>
                    </a>
                </el-menu-item>

              <el-menu-item v-if="!currentUser">
                <el-select v-model="$i18n.locale"
                           :no-match-text="$t('Ничего не найдено')"
                           filterable
                           style="margin-right: 8px; width: 50%"
                           :placeholder="$t('Выберите язык')">
                  <el-option
                      v-for="language in langOptions"
                      :key="language.label"
                      :label="language.label"
                      :value="language.value"
                  >
                  </el-option>
                </el-select>
              </el-menu-item>
                <!--                </div>-->
                <img alt="Графус" src="./assets/logo.png"/>


            </el-menu>
        </div>
        <router-view v-if="renderComponent" @login="prepareApp" @start-session="startSession"/>
        <div v-else v-loading="!renderComponent" style="height: 100%; margin-top: 100px"/>
    </div>
</template>

<script>

    import OntologyController from "./controllers/ontology.controller";
    // import {store} from "./store";
    import CaseSessionController from "./controllers/caseSession.controller";
    import CashController from "./controllers/cash.controller";
    import ProfileController from "./controllers/profile.controller";
    import { availableLanguages } from '@/i18n/i18n';

    export default {

        name: "app",
        data() {
            return {
                toComponent: '',
                renderComponent: false,
                playStatus: false,
                adminStatus: false,
                konstruktorStatus: false,
                kuratorStatus: false,
                analitikStatus: false,
                ontologyController: null,
                caseSessionController: null,
                cashController: null,
                CurrentSession: {caseName: this.$t('не выбрана')},
                allSessions: [],
                logInterval: null,
                langOptions: availableLanguages
            }
        },
        mounted() {
            this.ontologyController = new OntologyController()
            this.caseSessionController = new CaseSessionController()
            this.cashController = new CashController()
            this.profileController = new ProfileController()

            this.adminStatus = this.showAdminBoard
            this.konstruktorStatus = this.showKonstruktorBoard
            this.kuratorStatus = this.showKuratorBoard
            this.analitikStatus = this.showAnalitikBoard

            console.log('ADMIN STATUS=', this.adminStatus)
            if (this.currentUser) {
                this.prepareApp()
            } else {
                this.renderComponent = true
            }


        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            showAdminBoard() {
                if (this.currentUser && this.currentUser.roles.includes('ROLE_ADMIN')) {
                    this.profileController.setAdminStore()
                    return true
                }
                return false;
                // return true
            },
            showKonstruktorBoard() {
                if (this.currentUser && this.currentUser.roles) {

                    return this.currentUser.roles.includes('ROLE_KONSTRUKTOR');
                }
                return false;
                // return true
            },
            showKuratorBoard() {
                if (this.currentUser && this.currentUser.roles) {
                    return this.currentUser.roles.includes('ROLE_KURATOR');
                }
                return false;
                // return true
            },
            showAnalitikBoard() {
                if (this.currentUser && this.currentUser.roles) {
                    return this.currentUser.roles.includes('ROLE_ANALITIK');
                }
                return false;
                // return true
            },

        },
        components: {},
        props: {},
        methods: {
            startSession(obj) {
                this.playStatus = true
                this.caseSessionController.caseSessionByUserId(this.currentUser.id).then(() => {
                    this.allSessions = this.caseSessionController.getAllActiveSessionsStore()
                    this.setCurrentSession(this.caseSessionController.getSessionByCaseIdStore(obj.caseId))
                    this.$router.push('/session')
                })
            },
            prepareCashState(cash){
                let flag = true
                if (!('visualizator' in cash)) {
                    flag = true
                    cash.visualizator = {
                        window: '',
                        scheme: null
                    }
                }
                if (!('component' in cash)) {
                    flag = true
                    cash.component = 'instruction'
                }
                if (!('constructorS' in cash)) {
                    flag = true
                    cash.constructorS = {
                        scheme: null
                    }
                }
                if (!('displayAttribute' in cash)) {
                    flag = true
                    cash.displayAttribute = {}
                }
                if (!('revealed' in cash)) {
                    flag = true
                    cash.revealed = {
                        elements: []
                    }
                }
                if (!('listSubgraph' in cash)) {
                    flag = true
                    cash.listSubgraph = null
                }
                if (!('graphSubgraph' in cash)) {
                    flag = true
                    cash.graphSubgraph = null
                }
                if (!('marked' in cash)) {
                    flag = true
                    cash.marked = {}
                }
                if (!('cart' in cash)) {
                    flag = true
                    cash.cart = []
                }

                if (flag) this.cashController.updateCash(cash)
            },
            prepareSessionState(session){
                let flag = true



                if (!('allElements' in session.log)) {
                    flag = true
                    session.log.allElements = 0
                }
                if (!('currentElements' in session.log)) {
                    flag = true
                    session.log.currentElements = 0
                }
                if (flag) this.caseSessionController.updateCaseSession(session)
            },

            prepareApp() {
                this.adminStatus = this.showAdminBoard
                this.ontologyController.putOntologyToStore().then(() => {

                    const vm = this
                    this.caseSessionController.setCurrentSessionStore(null)
                    this.caseSessionController.setAllSessionsStore(null)

                    this.caseSessionController.caseSessionByUserId(this.currentUser.id).then(() => {
                            this.allSessions =  vm.caseSessionController.getAllActiveSessionsStore()
                            if (this.allSessions.length) {
                                for (let i = 0; i < this.allSessions.length; i++) {
                                    let session = this.allSessions[i]
                                    if (session !== null) {
                                        vm.prepareSessionState(session)
                                    }
                                    if (session.caseName === ('adminSession ' + vm.currentUser.username)) {
                                        vm.CurrentSession = this.allSessions[i]
                                        vm.setCurrentSession(vm.allSessions[i])
                                    } else {
                                        if (this.adminStatus && (i === this.allSessions.length)) {
                                            vm.caseSessionController.createAdminCaseSession(vm.adminStatus).then(() => {
                                                vm.setCurrentSession(vm.caseSessionController.getCurrentSessionStore())
                                                vm.caseSessionController.caseSessionByUserId(this.currentUser.id).then(() => {
                                                    vm.allSessions = vm.caseSessionController.getAllActiveSessionsStore()
                                                })
                                            })
                                            break
                                        }
                                        vm.CurrentSession = this.allSessions[0]
                                        vm.setCurrentSession(vm.allSessions[0])
                                    }
                                }
                            } else {
                                vm.caseSessionController.createAdminCaseSession(vm.adminStatus).then(() => {
                                    vm.setCurrentSession(vm.caseSessionController.getCurrentSessionStore())
                                    vm.caseSessionController.caseSessionByUserId(this.currentUser.id).then(() => {
                                        vm.allSessions = vm.caseSessionController.getAllActiveSessionsStore()
                                    })
                                })
                                vm.renderComponent = true;
                            }
                            // console.log('Current Session[store]', vm.caseSessionController.getCurrentSessionStore())
                        }
                    )
                })
            },
            logOut() {
                this.caseSessionController.setCurrentSessionStore(null)
                this.caseSessionController.setAllSessionsStore(null)
                this.$store.dispatch('auth/logout').then(
                    () => {
                        window.location.href = '/login';
                    },
                    error => {
                        this.loading = false;
                        this.message =
                            (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                    }
                );
                this.adminStatus = this.showAdminBoard;

            },
            async setCurrentSession(session) {
                if (session !== null) {
                    this.renderComponent = false;
                    this.CurrentSession = session
                    session.ontologyId = this.ontologyController.getOntology().id
                    await this.cashController.putCashToStore(session)
                    this.prepareCashState(this.cashController.getCashStore())
                    // if (this.logInterval !== null) clearInterval(this.logInterval)
                    // this.logInterval = setInterval(() => {
                    //     this.caseSessionController.updateCaseSession(session)
                    // }, 20000);
                    await this.caseSessionController.setCurrentSessionStore(session)
                    this.$nextTick(() => {
                        // Add the component back in
                        this.renderComponent = true;
                    });
                } else {
                    this.CurrentSession = null;
                }

            }

        }
    }
</script>

<style scoped src="./assets/index.css"></style>
<style src="./assets/css/style.css"></style>
<style scoped>


    img {
        float: right;
        width: 150px;
        vertical-align: middle !important;
        padding-top: 10px;
        padding-right: 10px
    }

    #app {
        /*font-family: "Avenir", Helvetica, Arial, sans-serif;*/
        -webkit-font-smoothing: antialiased;
        font-family: Helvetica;
        font-weight: 500;
        letter-spacing: .06em;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        font-size: 18px;
    }

    .el-menu {
        top: 0;
        position: sticky !important;
        z-index: 10000000000;
    }

    .router-link-exact-active {
        color: white !important;
        background-color: #4378ad;
        border-radius: 25px;
    }

    .selector-for-some-widget {
        box-sizing: content-box;
    }
</style>
