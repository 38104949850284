export const schemeStore = {
    namespaced: true,
    state: {
        schemeC: null,
        schemeV: null
    },
    getters: {
        getConstructorScheme: (state) => {
            return state.schemeC;
        },
        getVisScheme: (state) => {
            return state.schemeV;
        }
    },
    mutations: {
        setConstructorScheme: (state, scheme) => {
            state.schemeC = scheme;
        },
        setVisScheme: (state, scheme) => {
            state.schemeV = scheme;
        }
    }
};
